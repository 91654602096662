import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { purple } from "@mui/material/colors";
import LoadingButton from '@mui/lab/LoadingButton';


const ColorButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "var(--primary)",
  "&:hover": {
    backgroundColor: "var(--primary-light)",
  },
}));

export default ColorButton;
