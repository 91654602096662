import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CometChat } from "@cometchat-pro/chat";
import { APP_ID, REGION } from "./config";
import {
  CallAppSettingsBuilder,
  CometChatCalls,
} from "cometchat-pro-calls-js-sdk";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const region = "us";
const appID = "531a79aba17a4"
const appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(REGION)
  .autoEstablishSocketConnection(true)
  .overrideAdminHost(`${appID}.api-${region}.cometchat-staging.com/v3.0`)
  .overrideClientHost(`${appID}.apiclient-${region}.cometchat-staging.com/v3.0`)
  .build();
CometChat.init(APP_ID, appSetting).then(
  (initialized: boolean) => {
    console.log("Initialization completed successfully", initialized);
  },
  (error: CometChat.CometChatException) => {
    console.log("Initialization failed with error:", error);
  }
);

const appSettingCalls = new CallAppSettingsBuilder()
  .setAppId(appID)
  .setRegion(region)
  .build();

CometChatCalls.init(appSettingCalls);
